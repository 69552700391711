<template>
  <GrayBlockContainer class="w-[560px]">
    <div>
      <TitleWithSwitch title="標題" inactiveText="隱藏/顯示" :toggle.sync="shopInfoConfig.enabledTitle" />
      <el-form-item>
        <el-input v-model="shopInfoConfig.title" placeholder="圖文資訊範本名稱" />
      </el-form-item>
    </div>

    <div>
      <TitleWithSwitch title="店家名稱" inactiveText="隱藏/顯示" :toggle.sync="shopInfoConfig.showShopName" />
      <TitleWithSwitch title="店家電話" inactiveText="隱藏/顯示" :toggle.sync="shopInfoConfig.showShopPhone" />
      <TitleWithSwitch title="店家地址" inactiveText="隱藏/顯示" :toggle.sync="shopInfoConfig.showShopAddress" />
      <el-form-item>
        <el-input v-model="shopInfoConfig.customShopAddress" :disabled="copy" placeholder="請輸入地址" />
        <el-checkbox v-model="copy" @change="copyStoreAddress">套用分店設定的地址</el-checkbox>
      </el-form-item>

      <div>
        <TitleWithSwitch title="大按鈕" inactiveText="隱藏/顯示" :toggle.sync="googleBtn.enabled" />
        <p class="text-sm text-gray-80 mb-[8px]">此按鈕點擊後前往的 google 地圖為「分店設定的地址」</p>
        <div class="grid grid-cols-2 gap-[8px] mb-[20px]">
          <el-input v-model="googleBtn.label" />
          <el-input value="點擊後：開啟店家地址 google 地圖" disabled />
        </div>
      </div>
      <div>
        <TitleWithSwitch title="大按鈕" inactiveText="隱藏/顯示" :toggle.sync="openLinkBtn.enabled" />
        <div class="grid grid-cols-2 gap-[8px] mb-[20px]">
          <el-input v-model="openLinkBtn.label" placeholder="請輸入按鈕文字" />
          <el-input v-model="openLinkBtn.linkUrl" placeholder="請輸入連結" />
        </div>
      </div>
      <div>
        <TitleWithSwitch title="小按鈕" inactiveText="隱藏/顯示" :toggle.sync="shopPhoneBtn.enabled" />
        <div class="grid grid-cols-2 gap-[8px] mb-[20px]">
          <el-input v-model="shopPhoneBtn.label" />
          <el-input value="點擊後：撥打店家電話" disabled />
        </div>
      </div>
    </div>
  </GrayBlockContainer>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import TitleWithSwitch from '@/components/TitleWithSwitch.vue'
import { useVModel } from '@vueuse/core'
import { get, set, find } from 'lodash'
import { useShop } from '@/use/shop'
export default defineComponent({
  name: 'ShopInfoNotificationContentTemplate',
  components: { GrayBlockContainer, TitleWithSwitch },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    shopId: {
      type: String,
      default: '',
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const shopInfoConfig = useVModel(props, 'data', emit)
    const { shopAddress } = useShop()
    const copy = ref(false)
    const copyStoreAddress = () => {
      set(shopInfoConfig.value, 'customShopAddress', copy.value ? shopAddress.value : get(shopInfoConfig.value, 'customShopAddress'))
    }
    const googleBtn = computed(() => {
      return find(shopInfoConfig.value.actions, { type: 'googleMap' })
    })
    const openLinkBtn = computed(() => {
      return find(shopInfoConfig.value.actions, { type: 'openLink' })
    })
    const shopPhoneBtn = computed(() => {
      return find(shopInfoConfig.value.actions, { type: 'shopPhone' })
    })
    onMounted(async () => {
      if (get(props, 'isDefault') && !get(shopInfoConfig.value, 'customShopAddress')) {
        copy.value = true
        set(shopInfoConfig.value, 'customShopAddress', shopAddress.value)
      }
    })
    return {
      shopInfoConfig,
      copy,
      copyStoreAddress,
      googleBtn,
      openLinkBtn,
      shopPhoneBtn,
    }
  },
})
</script>

  <style lang="postcss" scoped>
::v-deep .el-input {
  @apply w-full
}
  </style>
