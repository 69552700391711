import { render, staticRenderFns } from "./ShopInfoNotificationContentTemplate.vue?vue&type=template&id=b3d3d808&scoped=true"
import script from "./ShopInfoNotificationContentTemplate.vue?vue&type=script&lang=js"
export * from "./ShopInfoNotificationContentTemplate.vue?vue&type=script&lang=js"
import style0 from "./ShopInfoNotificationContentTemplate.vue?vue&type=style&index=0&id=b3d3d808&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3d3d808",
  null
  
)

export default component.exports