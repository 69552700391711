import { ref } from 'vue'

export const useImageTextContent = () => {
  const contentList = ref([])
  const addBlock = (blockType) => {
    const blockData = {
      type: blockType,
      title: null,
    }
    if (blockType === 'image') {
      blockData.img = null
      blockData.action = 'none'
      blockData.link = null
    }
    if (blockType === 'html') blockData.content = null
    if (blockType === 'text') {
      blockData.content = undefined
      blockData.title = undefined
    }
    if (blockType === 'imageNoTitle') {
      blockData.type = 'image'
      blockData.content = undefined
      blockData.title = undefined
    }
    contentList.value.push(blockData)
  }

  const removeBlock = (idx) => {
    contentList.value.splice(idx, 1)
  }

  const moveData = (
    index,
    direction,
  ) => {
    if (index === 0 && direction === 'up') return
    const copy = [...contentList.value]
    if (index === (copy.length - 1) && direction === 'down') return
    const item = copy.splice(index, 1)[0]

    if (direction === 'up' && index > 0) {
      copy.splice(index - 1, 0, item)
    }

    if (direction === 'down' && index < copy.length) {
      copy.splice(index + 1, 0, item)
    }

    contentList.value = copy
  }

  return { contentList, addBlock, removeBlock, moveData }
}
