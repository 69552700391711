<template>
  <GrayBlockContainer class="w-[560px]">
    <div class="content-block-list">
      <div v-for="(block, idx) in contentList" :key="`${block.type}-${idx}`">
        <ImageBlockNoTitleEditBlock
          v-if="block.type === 'image'"
          :data.sync="contentList[idx]"
          @remove="removeBlock(idx)"
          @upLayer="moveData(idx, 'up')"
          @downLayer="moveData(idx, 'down')"
        />
        <TextBlockNoTitleEditBlock
          v-if="block.type === 'text'"
          :data.sync="contentList[idx]"
          @remove="removeBlock(idx)"
          @upLayer="moveData(idx, 'up')"
          @downLayer="moveData(idx, 'down')"
        />
      </div>
    </div>

    <div class="flex items-center">
      <AddButton content="新增文字" @click="addBlock('text')" />
      <AddButton content="新增圖片" @click="addBlock('imageNoTitle')" />
    </div>
  </GrayBlockContainer>
</template>

<script>
import { defineComponent, onMounted, watch } from 'vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import TextBlockNoTitleEditBlock from '@/components/TextBlockNoTitleEditBlock.vue'
import ImageBlockNoTitleEditBlock from '@/components/ImageBlockNoTitleEditBlock.vue'
import { useImageTextContent } from '@/use/useImageTextContent'

export default defineComponent({
  name: 'CustomImageTextNotificationContent',
  components: {
    GrayBlockContainer,
    TextBlockNoTitleEditBlock,
    ImageBlockNoTitleEditBlock,
  },
  props: {
    contentData: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const { contentList, addBlock, removeBlock, moveData } = useImageTextContent()

    watch(contentList, () => {
      emit('change', contentList.value)
    }, { deep: true })

    const unWatch = watch(() => props.contentData, () => {
      contentList.value = props.contentData
      unWatch()
    }, { deep: true })

    onMounted(() => {
      contentList.value = props.contentData
    })

    return { contentList, addBlock, removeBlock, moveData }
  },
})
</script>

<style lang="postcss" scoped>

</style>
